.red:hover {
	color: red;
}
.smoke {
	color: whitesmoke;
}

.black {
	color: black
}

.white {
	color: white;
}
.cursor {
	cursor: pointer;
}
.f18 {
	font-size: 18px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}


.create-card-button {
	height: 56px;
	 font-weight: bold;
	 margin-left: 5;
	 font-size:"18px";
	  width:150px;
	 cursor:pointer;
	 background-color: black;
	 color: white;
	 border: none;
	 box-shadow: 0 12px 24px 0 rgba(0,0,0,0.2);
	 border-radius: 10px;

}

button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.menu-card {
	display: flex;
	cursor: pointer;
	margin-top: 20px;
	justify-content: center;
	align-items: center;
	color: black;
	height: 100px;
	width: 95%;
	border-radius: 10px;
	background-color: white;
	border: none;
}

.menu-card:hover {
	display: flex;
	cursor: pointer;
	margin-top: 20px;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: #1ea1f2;
	height: 100px;
	width: 95%;
	border-radius: 10px;
	border: none;
}

.black-link {
	font-weight: bold;
	text-decoration: none;
	font-size: 24px;
	color: inherit;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.snapshot-card {
	cursor: pointer;
	height: 150px;
	border-radius: 20px;
	width: 300px;
	margin-top: 30px;
	margin-left: 30px;
	margin-right: 5px;
	background-color: white;
	color: black;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.snapshot-card:hover {
	cursor: pointer;
	height: 150px;
	border-radius: 20px;
	width: 300px;
	margin-top: 30px;
	margin-left: 30px;
	margin-right: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 12px 24px 0 rgba(0,0,0,0.2);
}

.round-button {
	margin-right: 10%;
	color: black;
	font-weight:bold;
	padding:15px;
	padding-left: 50px;
	padding-right: 50px;
	border-radius: 25px;
	background-color: white;
	cursor: pointer;
	text-decoration: none;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.round-button:hover {
	margin-right: 10%;
	color: black;
	font-weight:bold;
	padding:15px;
	padding-left: 50px;
	padding-right: 50px;
	border-radius: 25px;
	box-shadow: 0 12px 24px 0 rgba(0,0,0,0.2);
	text-decoration: none;
}


.settings-card {
	border-radius: 20px;
	width: 60vw;
	height: 80vh;
	background-color: whitesmoke;
	color: black;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	font-weight: bold;
	font-size: 24px;
}

.settings-card:hover {
	border-radius: 20px;
	width: 60vw;
	height: 80vh;
	background-color: whitesmoke;
	color: black;
	display: flex;
	justify-content: center;
	align-items: center;
}

.large-button {
	cursor: pointer;
	color: white;
	font-weight: bold;
	font-size: 24px;
	background-color: black;
	border: none;
	border-radius: 5px;
	height: 65px;
	width: 310px;
	margin-bottom: 20px;
	outline: "none";
}

.large-button:hover {
	cursor: pointer;
	color: white;
	font-weight: bold;
	font-size: 24px;
	background-color: black;
	border: none;
	border-radius: 5px;
	height: 65px;
	width: 310px;
	margin-bottom: 20px;
	outline: "none";
	box-shadow: 0 12px 24px 0 rgba(0,0,0,0.2);
}
